<template lang="pug">
    figure(v-html="contents.content", v-bind="contents.attributes")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-figure",
    props,
}
</script>

<style lang="stylus" scoped src="./Figure.styl"></style>
